import { FormKitSchemaNode } from '@formkit/core';

export const ElementEditorHeading = (heading: string): FormKitSchemaNode => {
	return {
		$el: 'h2',
		attrs: {
			class: { 'col-span-12': true, 'font-semibold': true },
		},
		children: heading,
	};
};

export const ElementBox = (childrenElements: FormKitSchemaNode[]): FormKitSchemaNode => {
	return {
		$el: 'div',
		attrs: {
			class: { 'formkit-invalid:border-red-500': true, 'col-span-12': true, rounded: true, border: true, 'b-2': true, grid: true, 'grid-cols-12': true, 'gap-y-4': true, 'p-2': true, 'mb-4': true },
		},
		children: childrenElements,
	};
};

export const BuildElementBox = (heading: string, baseOptions: FormKitSchemaNode[], beforeOptions: FormKitSchemaNode[] = [], afterOptions: FormKitSchemaNode[] = []): FormKitSchemaNode[] => {
	return [ElementEditorHeading(heading), ElementBox(beforeOptions.concat(baseOptions, afterOptions))];
};

export const BaseRequiredOptions = [
	{
		$formkit: 'text',
		name: 'name',
		label: 'Input name',
		validation: [['safeAlphaNumeric'], ['required']],
		help: 'What is the name of the input field. (required) (unique)',
		outerClass: {
			'col-span-12': true,
			'sm:col-span-12': true,
			'lg:col-span-12': true,
			'xl:col-span-12': true,
			'px-1': true,
		},
	},
];

export const BasicOptionalOptions = [
	{
		$formkit: 'text',
		name: 'label',
		label: 'Input label',
		help: 'What is the label of the input field. (optional)',
		outerClass: {
			'col-span-12': true,
			'sm:col-span-12': true,
			'lg:col-span-12': true,
			'xl:col-span-6': true,
			'px-1': true,
		},
	},
	{
		$formkit: 'text',
		name: 'help',
		label: 'Helper text',
		help: 'What is the helper of the input field. (optional)',
		outerClass: {
			'col-span-12': true,
			'sm:col-span-12': true,
			'lg:col-span-12': true,
			'xl:col-span-6': true,
			'px-1': true,
		},
	},
];

export const OptionalOptions = [
	{
		$formkit: 'text',
		name: 'label',
		label: 'Input label',
		help: 'What is the label of the input field. (optional)',
		outerClass: {
			'col-span-12': true,
			'sm:col-span-12': true,
			'lg:col-span-12': true,
			'xl:col-span-6': true,
			'px-1': true,
		},
	},
	{
		$formkit: 'text',
		name: 'placeholder',
		label: 'Placeholder',
		help: 'What is the placeholder of the input field. (optional)',
		outerClass: {
			'col-span-12': true,
			'sm:col-span-12': true,
			'lg:col-span-12': true,
			'xl:col-span-6': true,
			'px-1': true,
		},
	},
	{
		$formkit: 'text',
		name: 'help',
		label: 'Helper text',
		help: 'What is the helper of the input field. (optional)',
		outerClass: {
			'col-span-12': true,
			'sm:col-span-12': true,
			'lg:col-span-12': true,
			'xl:col-span-6': true,
			'px-1': true,
		},
	},
];

export const BaseDesignOptions = [
	{
		$formkit: 'classes',
		name: 'outerClass',
		preserve: true,
		directReturn: true,
		label: 'Mobile column width',
		classOptions: {
			'col-span-1': '1 columns',
			'col-span-2': '2 columns',
			'col-span-3': '3 columns',
			'col-span-4': '4 columns',
			'col-span-5': '5 columns',
			'col-span-6': '6 columns',
			'col-span-7': '7 columns',
			'col-span-8': '8 columns',
			'col-span-9': '9 columns',
			'col-span-10': '10 columns',
			'col-span-11': '11 columns',
			'col-span-12': '12 columns',
		},
		outerClass: {
			'col-span-12': true,
			'sm:col-span-12': true,
			'lg:col-span-12': true,
			'xl:col-span-6': true,
			'px-1': true,
		},
	},
	{
		$formkit: 'classes',
		name: 'outerClass',
		preserve: true,
		directReturn: true,
		label: 'Tablet column width',
		classOptions: {
			'sm:col-span-1': '1 columns',
			'sm:col-span-2': '2 columns',
			'sm:col-span-3': '3 columns',
			'sm:col-span-4': '4 columns',
			'sm:col-span-5': '5 columns',
			'sm:col-span-6': '6 columns',
			'sm:col-span-7': '7 columns',
			'sm:col-span-8': '8 columns',
			'sm:col-span-9': '9 columns',
			'sm:col-span-10': '10 columns',
			'sm:col-span-11': '11 columns',
			'sm:col-span-12': '12 columns',
		},
		outerClass: {
			'col-span-12': true,
			'sm:col-span-12': true,
			'lg:col-span-12': true,
			'xl:col-span-6': true,
			'px-1': true,
		},
	},
	{
		$formkit: 'classes',
		name: 'outerClass',
		preserve: true,
		directReturn: true,
		label: 'Laptop column width',
		classOptions: {
			'lg:col-span-1': '1 columns',
			'lg:col-span-2': '2 columns',
			'lg:col-span-3': '3 columns',
			'lg:col-span-4': '4 columns',
			'lg:col-span-5': '5 columns',
			'lg:col-span-6': '6 columns',
			'lg:col-span-7': '7 columns',
			'lg:col-span-8': '8 columns',
			'lg:col-span-9': '9 columns',
			'lg:col-span-10': '10 columns',
			'lg:col-span-11': '11 columns',
			'lg:col-span-12': '12 columns',
		},
		outerClass: {
			'col-span-12': true,
			'sm:col-span-12': true,
			'lg:col-span-12': true,
			'xl:col-span-6': true,
			'px-1': true,
		},
	},
	{
		$formkit: 'classes',
		name: 'outerClass',
		preserve: true,
		directReturn: true,
		label: 'Desktop column width',
		classOptions: {
			'xl:col-span-1': '1 columns',
			'xl:col-span-2': '2 columns',
			'xl:col-span-3': '3 columns',
			'xl:col-span-4': '4 columns',
			'xl:col-span-5': '5 columns',
			'xl:col-span-6': '6 columns',
			'xl:col-span-7': '7 columns',
			'xl:col-span-8': '8 columns',
			'xl:col-span-9': '9 columns',
			'xl:col-span-10': '10 columns',
			'xl:col-span-11': '11 columns',
			'xl:col-span-12': '12 columns',
		},
		outerClass: {
			'col-span-12': true,
			'sm:col-span-12': true,
			'lg:col-span-12': true,
			'xl:col-span-6': true,
			'px-1': true,
		},
	},
];

export const StaticDesignOptions = [
	{
		$formkit: 'classes',
		name: 'class',
		label: 'Mobile column width',
		staticElement: true,
		classOptions: {
			'col-span-1': '1 columns',
			'col-span-2': '2 columns',
			'col-span-3': '3 columns',
			'col-span-4': '4 columns',
			'col-span-5': '5 columns',
			'col-span-6': '6 columns',
			'col-span-7': '7 columns',
			'col-span-8': '8 columns',
			'col-span-9': '9 columns',
			'col-span-10': '10 columns',
			'col-span-11': '11 columns',
			'col-span-12': '12 columns',
		},
		outerClass: {
			'col-span-12': true,
			'sm:col-span-12': true,
			'lg:col-span-12': true,
			'xl:col-span-6': true,
			'px-1': true,
		},
	},
	{
		$formkit: 'classes',
		name: 'class',
		label: 'Tablet column width',
		staticElement: true,
		classOptions: {
			'sm:col-span-1': '1 columns',
			'sm:col-span-2': '2 columns',
			'sm:col-span-3': '3 columns',
			'sm:col-span-4': '4 columns',
			'sm:col-span-5': '5 columns',
			'sm:col-span-6': '6 columns',
			'sm:col-span-7': '7 columns',
			'sm:col-span-8': '8 columns',
			'sm:col-span-9': '9 columns',
			'sm:col-span-10': '10 columns',
			'sm:col-span-11': '11 columns',
			'sm:col-span-12': '12 columns',
		},
		outerClass: {
			'col-span-12': true,
			'sm:col-span-12': true,
			'lg:col-span-12': true,
			'xl:col-span-6': true,
			'px-1': true,
		},
	},
	{
		$formkit: 'classes',
		name: 'class',
		label: 'Laptop column width',
		staticElement: true,
		classOptions: {
			'lg:col-span-1': '1 columns',
			'lg:col-span-2': '2 columns',
			'lg:col-span-3': '3 columns',
			'lg:col-span-4': '4 columns',
			'lg:col-span-5': '5 columns',
			'lg:col-span-6': '6 columns',
			'lg:col-span-7': '7 columns',
			'lg:col-span-8': '8 columns',
			'lg:col-span-9': '9 columns',
			'lg:col-span-10': '10 columns',
			'lg:col-span-11': '11 columns',
			'lg:col-span-12': '12 columns',
		},
		outerClass: {
			'col-span-12': true,
			'sm:col-span-12': true,
			'lg:col-span-12': true,
			'xl:col-span-6': true,
			'px-1': true,
		},
	},
	{
		$formkit: 'classes',
		name: 'class',
		label: 'Desktop column width',
		staticElement: true,
		classOptions: {
			'xl:col-span-1': '1 columns',
			'xl:col-span-2': '2 columns',
			'xl:col-span-3': '3 columns',
			'xl:col-span-4': '4 columns',
			'xl:col-span-5': '5 columns',
			'xl:col-span-6': '6 columns',
			'xl:col-span-7': '7 columns',
			'xl:col-span-8': '8 columns',
			'xl:col-span-9': '9 columns',
			'xl:col-span-10': '10 columns',
			'xl:col-span-11': '11 columns',
			'xl:col-span-12': '12 columns',
		},
		outerClass: {
			'col-span-12': true,
			'sm:col-span-12': true,
			'lg:col-span-12': true,
			'xl:col-span-6': true,
			'px-1': true,
		},
	},
];

export const BaseValidationOptions = [
	{
		$formkit: 'validation',
		name: 'validation',

		outerClass: {
			'col-span-12': true,
			'sm:col-span-12': true,
			'lg:col-span-12': true,
			'xl:col-span-12': true,
		},
	},
];

export const BaseConditionalOptions = [
	{
		$formkit: 'conditional',
		name: 'if',
		label: 'Conditionals',
		preserve: true,
		outerClass: {
			'col-span-12': true,
			'sm:col-span-12': true,
			'lg:col-span-12': true,
			'xl:col-span-6': true,
			'px-1': true,
		},
	},
];
export const BaseExpressionOptions = [
	{
		$formkit: 'expression',
		name: 'expression',
		label: 'Expression',
		preserve: true,
		outerClass: {
			'col-span-12': true,
			'sm:col-span-12': true,
			'lg:col-span-12': true,
			'xl:col-span-6': true,
			'px-1': true,
		},
	},
];

export const BaseSelectOptionsOptions = [
	{
		$formkit: 'selectOptions',
		name: 'options',

		outerClass: {
			'col-span-12': true,
			'sm:col-span-12': true,
			'lg:col-span-12': true,
			'xl:col-span-12': true,
		},
	},
];

export const BaseBuilderOptions = [
	{
		$formkit: 'group',
		name: 'meta',
		preserve: false,
		children: [
			{
				$formkit: 'textarea',
				name: 'note',
				label: 'Builder notes',
				outerClass: {
					'col-span-12': true,
					'sm:col-span-12': true,
					'lg:col-span-12': true,
					'xl:col-span-12': true,
				},
			},
		],
		outerClass: {
			'col-span-12': true,
			'sm:col-span-12': true,
			'lg:col-span-12': true,
			'xl:col-span-12': true,
		},
	},
];
